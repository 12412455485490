import Container from '@components/layout/Container'
import FullScreenSection from '@components/layout/FullScreenSection'
import PageSeo from '@components/seo/PageSeo'
import FullScreenVideo from '@components/video/FullScreenVideo'
import useMediaQuery, { maxWidthQueries } from '@operations/utils/useMediaQuery'
import Link from 'next/link'
import React from 'react'
import { useTheme } from 'styled-components'

export default function Custom404() {
  const theme = useTheme()
  const isMobile = useMediaQuery(maxWidthQueries.sm)

  return (
    <FullScreenSection align="center" justify="center" wrap="wrap">
      <PageSeo
        description="Ooops! We were unable to climb this mountain!"
        title="Page not found | We couldn't climb this mountain | Skyclimbers"
      />
      <FullScreenVideo
        mp4="/images/squad_gif.mp4"
        style={{ filter: 'brightness(0.9)' }}
        webm="/images/squad_gif.webm"
      />
      <Container
        align="center"
        background={{ backgroundColor: 'rgba(255,255,255, .8)' }}
        direction="column"
        justify="center"
        padding={isMobile ? '3rem 1rem' : '6rem 2rem'}
        position="absolute"
        wrap="wrap"
      >
        <h1 style={{ textAlign: 'center' }}>Ooops! We were unable to climb this mountain!</h1>
        <p style={{ textAlign: 'center' }}>(In other words... We are unable to find this page)</p>
        <h4 style={{ textAlign: 'center' }}>
          Try to fly up into the sky and look out for a different mountain.
        </h4>
        <div style={{ fontSize: '1.5rem' }}>
          <Link href="/shop">
            <a
              style={{
                color: theme.colors.black,
                marginRight: '1rem',
                textDecoration: 'underline',
              }}
            >
              Shop
            </a>
          </Link>
          <Link href="/sentipedia">
            <a
              style={{
                color: theme.colors.black,
                marginRight: '1rem',
                textDecoration: 'underline',
              }}
            >
              Sentipedia
            </a>
          </Link>
          <Link href="/world">
            <a
              style={{
                color: theme.colors.black,
                textDecoration: 'underline',
              }}
            >
              World
            </a>
          </Link>
        </div>
      </Container>
    </FullScreenSection>
  )
}
